import React, { useEffect, useState, useRef } from 'react';
import { FaHandsHelping, FaTrophy, FaArrowLeft, FaTicketAlt } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMail, AiFillBell } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  Container,
  Button,
  Card,
  CardBody,
  CardDeck,
  CardGroup,
  CardHeader,
  CardTitle,
  CardFooter,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Media,
  FormText, ModalHeader, ModalBody, ModalFooter, Modal,
} from 'reactstrap';
import PlaceholderAvatar from '../assets/profile_placeholder.png';
import { BsArrowLeftShort, BsBell, BsEnvelope } from 'react-icons/bs';
import { MdLogout } from 'react-icons/md';
import axios from 'axios';
import { IoIosArrowRoundBack } from 'react-icons/io';
import PageSpinner from 'components/PageSpinner';
import { AiFillCheckCircle } from 'react-icons/ai';

const initialFormData = Object.freeze({
  first_name: '',
  last_name: '',
  email: '',
  role: ''
});

export function InfoBox(props) {
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!props.show)
    return null;

  return (
    <div ref={ref} className='card border-0 m-0'>
      {props.children}
    </div>);
}

const Header = ({ head, description }) => {
  const [profilePopUp, setProfilePopup] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
  const currentUser = cookies.currentUser;
  const isLoggedIn = cookies.isLoggedIn;
  const [userData, setUserData] = useState(initialFormData);
  const userRole = cookies.userRole;
  const [error, setError] = useState();
  const [userLoading, setUserLoading] = useState(true);
  let navigate = useNavigate();
  const [notificationPopUp, setNotificationPopUp] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [reloadCount, setReloadCount] = useState(1);

  const [logoutShow, setLogoutShow] = useState(false);

  const toggleLogout = () => {
    setLogoutShow(true);
  }

  const handleClose = () => {
    setLogoutShow(false);
  }

  const toggleProfilePopup = () => {
    setProfilePopup(!profilePopUp);
  }

  const Logout = () => {
    if (userRole == "Admin") {
      navigate("/admin/login");
      removeCookies('currentUser', { path: '/' });
      removeCookies('isLoggedIn', { path: '/' });
      removeCookies('userRole', { path: '/' });
    } else {
      navigate("/login");
      removeCookies('currentUser', { path: '/' });
      removeCookies('isLoggedIn', { path: '/' });
      removeCookies('userRole', { path: '/' });
    }
    setLogoutShow(false);
  }
  const toggleProfile = () => {
    window.location.reload(false);
  }

  const location = useLocation()
  var currentPathName = location.pathname;

  async function markAsReadSubmit(e) {
    if (currentUser != '') {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_ENDPOINT + 'notification/' + e + '/read',
        data: ({
          user_id: currentUser
        })
      }).then(function (response) {
        if (response.data.status == 'Success') {
          setReloadCount(reloadCount + 1);

        } else {
          toast.error('An error occured. Please try again or contact the administrator.', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(function (error) {
        // setError(error)
        console.log(error);
        toast.error('An error occured. Please try again or contact the administrator.', {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    } else {
      toast.warning('You are not logged in!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate('/login');
    }
  }

  useEffect(() => {

    if (isLoggedIn) {

    } else {
      navigate("/login");
    }

  }, []);

  useEffect(() => {
    if (currentPathName === "/login") {
      document.body.classList.add('login-overflow');
    } else {
      document.body.classList.remove('login-overflow');
    }
  }, [currentPathName]);


  useEffect(() => {
    // This is the axios GET
    axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser).then((response) => {
      const data = response.data;
      const result = data.data;
      if (result) {
        setUserData(result);
        setUserLoading(false);
      }
    }).catch(error => {
      setError(error);
    });


    axios.get(process.env.REACT_APP_API_ENDPOINT + 'notification?user_id=' + currentUser).then((response) => {
      const data = response.data;
      const result = data.data;
      if (result) {
        setNotificationData(result);
        if (result.length > 0) {
          var unreadNotifcations = result.filter(item => !item.read_at)
          setNotificationsCount(unreadNotifcations.length);
        }
        
        setNotificationLoading(false);
      }
    }).catch(error => {
      setError(error);
    });

  }, [reloadCount]);

  return (
    <>
      {isLoggedIn && currentPathName != "/login" && currentPathName != "/admin/login" && currentPathName != "/registration" ?
        <section id="header">
          <Row>
            <Col lg="6">
            </Col>
            <Col lg="6" className="d-flex justify-content-end align-items-center">
              <div className="notification-container">
                <span className="notification-count">{notificationsCount > 99 ? "99" : notificationsCount}</span>
                <BsBell size="30" className='mx-4 cursor-pointer' onClick={() => { setNotificationPopUp(!notificationPopUp) }} />
              </div>
              <InfoBox show={notificationPopUp} onClickOutside={() => { setNotificationPopUp(false) }}>
                <Card className="notification-show">
                  <CardBody className='position-relative'>
                    {/* <p className='text-center'>No records found</p> */}
                    {/* <Media className="font-style n notification-single media">
                      <div className="align-self-center pe-3 media-left">
                        <a href="/" className="text-decoration-none">
                          <img src={PlaceholderAvatar} alt="Avatar" className="rounded-circle media-object" style={{ width: '40px', height: '40px' }} />
                        </a>
                      </div>
                      <div className="align-self-center media-body media-middle">
                        <a href="/" className="text-decoration-none header-notification">
                          <span className="font-weight-bold">A listing has been unlocked</span><br />
                          A listing that you requested is unlocked.<br />
                          <small className="text-muted">{new Date("December 20, 2022").toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}</small> <br />
                        </a>
                      </div>
                      <div className="align-self-center media-right"></div>
                    </Media> */}
                    {notificationLoading ?
                      <>
                        <p className='text-center'><PageSpinner /></p>
                      </>
                      :
                      <>
                        {notificationData.length > 0 ?
                          <>
                            {notificationData.map(({ id, read_at, created_at, subject, message, url }, index) => (
                              <>
                              {read_at ?
                                null
                                :
                                <>
                                  <Media className="font-style n notification-single media">
                                    <div className="align-self-center pe-3 media-left">
                                      <a href={url} className="text-decoration-none">
                                        <img src={PlaceholderAvatar} alt="Avatar" className="rounded-circle media-object" style={{ width: '40px', height: '40px' }} />
                                      </a>
                                    </div>
                                    <div className="align-self-center media-body media-middle">
                                      <a href={url} className="text-decoration-none header-notification">
                                        <span className='font-weight-bold'>{subject ? subject : "-"}</span><br />{message ? message : "-"}<br />
                                        <small className="text-muted">{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</small> <br />
                                      </a>
                                    </div>
                                    <div className="align-self-center media-right"></div>
                                    <Media right className="align-self-center cursor-pointer">
                                      <AiFillCheckCircle color="#932F8C" size="20" onClick={function (event) { markAsReadSubmit(id) }} />
                                    </Media>
                                  </Media>
                                  <hr />
                                </>
                              }
                                
                              </>
                            ))}
                            <a href="/notifications">
                              <p className='text-center'>View all notifications</p>
                            </a>
                          </>
                          :
                          <>
                            <p className='text-center'>No records found</p>
                          </>
                        }
                      </>
                    }
                    {/* <p className='text-center'>No records found</p> */}
                  </CardBody>
                  {/* <CardFooter className='see-all-notif'>
                    <p className='mb-0 text-center py-1'>See all notifications</p>
                  </CardFooter> */}
                </Card>
              </InfoBox>
              <div className="header_action_table">
                {profilePopUp ?
                  <img src={PlaceholderAvatar} id="ss-round-avatar" className='mx-4' onClick={() => { setProfilePopup(false) }} />
                  :
                  <img src={PlaceholderAvatar} id="ss-round-avatar" className='mx-4' onClick={() => { setProfilePopup(true) }} />
                }
                <InfoBox show={profilePopUp} onClickOutside={() => { setProfilePopup(false) }}>
                  <Card className="profile-show">
                    <CardBody className="p-0">
                      <div className="profile-head-info" onClick={() => navigate('/profile')}>
                        <Row>
                          <Col lg="4">
                            <img src={PlaceholderAvatar} className='profile-avatar' onClick={() => navigate('/profile')} />
                          </Col>
                          <Col lg="8" className='ps-0'>
                            <h4 className="user-info">Hi, {userData.first_name}!</h4>
                            <small className='mb-0'><IoIosArrowRoundBack />View Profile</small>
                          </Col>
                        </Row>
                      </div>
                      <hr className='m-0' />
                      <div className="logout-button" onClick={toggleLogout}>
                        <MdLogout />
                        <a>Logout</a>
                      </div>
                    </CardBody>
                  </Card>
                </InfoBox>
              </div>
            </Col>

          </Row>
        </section>
        :
        null
      }
      <Modal
        isOpen={logoutShow}
        centered
        fade
        id="modal"
      >
        <ModalHeader>
          LOGOUT
        </ModalHeader>
        <ModalBody>
          <p className='mb-0'>Are you sure, you want to log out?</p>
        </ModalBody>
        <ModalFooter className='text-end'>
          <button className='button-outline cancel-btn me-2' id="login-btn" onClick={handleClose}>CANCEL</button>
          <button className='button view-button' id="login-btn" onClick={Logout}>LOGOUT</button>
        </ModalFooter>
      </Modal>
    </>

  )
}

export default Header;
