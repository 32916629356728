// components
import Header from "../components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  return (
    <>
      {/* <ToastContainer style={{ width: "370px" }} /> */}
      <Header/>
        <main>{children}</main>
    </>
  );
};

export default Layout;
